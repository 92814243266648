import { useEffect, useState } from "react";

/**
 *    Fetch data, compatible with Suspense.
 *    How it works:
 *    - Start the request by calling fetchData() outside the suspended component, e.g.
 *      `const getEntries = fetchData("/entries");`
 *      It will return a callback that could be used to retrieve the results of the requests.
 *    - Use the callback in any place of the suspended component to get the data, e.g.
 *      `const entries = getEntries();`
 *    - While the data is loading, it will throw a special type of error for Suspense to catch.
 *      This way Suspense will understand that the data is not ready yet.
 *    - When the data is ready, the callback will return the data itself (not a promise!) that could be used as usual in the component.
 */
export function fetchData<T = unknown>(url: string, options: RequestInit = {}) {
    let status: "pending" | "rejected" | "fulfilled" = "pending";
    let result: unknown;

    const mockData = getMockData(url, {}, options["body"]);

    const dataPromise = mockData
        ? returnMockData(mockData)
        : fetch(url, options).then((response: Response) => {
              if (!response.ok) {
                  status = "rejected";
                  result = new Error("server error");
              }
              return response.json();
          });

    const fetching = dataPromise
        .then((data: unknown) => {
            status = "fulfilled";
            result = data;
        })
        // Fetch request has failed
        .catch((error: unknown) => {
            status = "rejected";
            result = error;
        });

    return (): T => {
        switch (status) {
            case "pending":
                throw fetching; // Suspend(A way to tell React data is still fetching)
            case "rejected":
                throw result; // Result is an error
            case "fulfilled":
                return result as T; // Result is a fulfilled promise
        }
    };
}

/**
 * add nonce to fetch requests options
 */
export const getKMSHeader = (): RequestInit => {
    // nonce from globals
    const nonce = document.head.querySelector("[name=xsrf-ajax-nonce]")?.getAttribute("content");

    // add nonce to the request headers
    return {
        headers: nonce ? { "X-Kms-Nonce": nonce } : {},
    };
};

/**
 * update nonce in the document head dedicated meta
 * @param value
 */
export const updateNonce = (value: string) => {
    const nonceMeta = document.head.querySelector("[name=xsrf-ajax-nonce]");
    if (nonceMeta) {
        nonceMeta.setAttribute("content", value);
    }
};

/**
 * reference used to abort previous requests
 */
let lastAbortableRequestCtrl: AbortController | undefined;
/**
 * fetch data from kms - compatible with kms nonce security and Suspense.
 *
 * Use-case: fetch data on component load (one time, or dynamically based on changing data).
 */
export function fetchKmsData<T = unknown>(url: string, data: unknown = undefined, abortable = false) {
    // abort previous request if any valid for abortion.
    // only an abortable request may abort other requests.
    if (abortable && lastAbortableRequestCtrl) {
        lastAbortableRequestCtrl.abort();
        lastAbortableRequestCtrl = undefined;
    }

    let options = getKMSHeader();

    // is this post
    if (data) {
        // add post headers and data
        options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
            ...options,
        };
        if (abortable) {
            const ctrl = new AbortController();
            options["signal"] = ctrl.signal;
            lastAbortableRequestCtrl = ctrl;
        }
    }

    return fetchData<T>(url, options);
}

/**
 * get data using fetch - no Suspense support.
 */
async function getData(url: string, options = {}) {
    const mockData = getMockData(url);
    if (mockData) {
        return returnMockData(mockData);
    }

    const response = await fetch(url, { method: "GET", ...options });

    // validate response
    if (!response.ok) {
        throw new Error("fetch error");
    }

    return await response.json();
}

/**
 * get data from kms - compatible with kms nonce security. no Suspense support.
 *
 * Use-case: load data on event, if the call doesn't require sending parameters (otherwise, use postKmsData).
 */
export async function getKmsData(url: string) {
    const options = getKMSHeader();
    return getData(url, options);
}

/**
 * post data using fetch - no Suspense support.
 */
async function postData(url: string, options = {}, data = {}) {
    const mockData = getMockData(url, {}, data);

    if (mockData) {
        return returnMockData(mockData);
    }

    const response = await fetch(
        url,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data), // body data type must match "Content-Type" header
            ...options,
        }
    );

    // validate response
    if (!response.ok) {
        throw new Error("fetch error");
    }

    return await response.json();
}

/**
 * post data to kms - compatible with kms nonce security. no Suspense support.
 *
 * Use-case: perform an action on event, e.g. send form data.
 */
export async function postKmsData(url: string, data = {}) {
    const options = getKMSHeader();
    return postData(url, options, data);
}

/**
 * React hook to post data to KMS when `url` or `data` changes - compatible with KMS nonce security.
 *
 * Use-case: load data based on data that changes dynamically (e.g. based on filters),
 *     (!!!) only if using Suspense for the same purpose introduces bad data flow between the components.
 *
 * Important: `data` must be memoized!
 */
export function usePostKmsData<T = unknown>(url: string, data: any): ["loading" | "loaded" | "error", T | undefined] {
    const [state, setState] = useState<"loading" | "loaded" | "error">("loading");
    const [response, setResponse] = useState<T>();

    useEffect(() => {
        setState("loading");

        const ctrl = new AbortController();
        const options = getKMSHeader();
        options.signal = ctrl.signal;
        (async () => {
            try {
                const response = await postData(url, options, data);
                setResponse(response);
                setState("loaded");
            }
            catch (e) {
                console.error(`Failed to fetch data from ${url}:`, e);
                setState("error");
            }
        })();

        return () => {
            ctrl.abort();
        };
    }, [url, data]);

    return [state, response];
}

export const getMockData = (url: string, data: unknown = {}, postParams: any = undefined) => {
    // outside of storybook, do nothing
    if (!(window as any).storybookMockData) {
        return null;
    }

    const key = url + (typeof postParams === "string" ? postParams : postParams ? JSON.stringify(postParams) : "");

    // first, search with post params if we have them
    let mockData = (window as any).storybookMockData?.[key];
    // search without post params - it may have been indexed without them(most likely)
    if (!mockData && postParams) {
        mockData = (window as any).storybookMockData?.[url];
    }

    //added for backward compatibility with previous solution - if we dont have the full url with query params, search without them
    if (!mockData) {
        const urlWithNoQuery = url.split("?")[0];
        mockData = (window as any).storybookMockData?.[urlWithNoQuery];
    }

    return typeof mockData === "function" ? mockData(data) : mockData;
};

const returnMockData = async (data: unknown) => {
    if (data instanceof Error) {
        throw data;
    }

    return data;
};
